.logo {
  margin: 2em 0 2em 2em;
  overflow: hidden;
}

.logo img {
  /* width: 120px; */
  height: 40px;
}

.fm {
  font-size: 8px;
  letter-spacing: 6px;
  text-transform: uppercase;
  padding-left: 42px;
  white-space: nowrap;
}

.fm.collapsed {
  padding-left: 0;
  letter-spacing: 2px;
  padding-top: 1px;
}
