@import "~antd/dist/antd.dark.less";
@import "quirks.less";

@font-family: "Roboto", "arial", sans-serif;
@code-family: "Fira Code", "SFMono-Regular", Consolas, "Liberation Mono", Menlo,
  Courier, monospace;

@menu-bg: transparent;

@primary-color: #14ffeb; // primary color for all components
@btn-primary-color: #000;
