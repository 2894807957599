// Prevent badge status wrap-around on small screens
.ant-badge-status {
  white-space: nowrap;
}

// Remove ugly border on menu
.ant-menu-inline,
.ant-menu-inline-collapsed {
  border: 0;
}

.terminal-log {
  background: #000;
  padding: 1em;
  white-space: pre-wrap;
  min-height: 10em;
  height: 10em;
  margin: 0;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  resize: vertical;
}

.terminal-log::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.terminal-log::-webkit-scrollbar,
.terminal-log::-webkit-scrollbar-track,
.terminal-log::-webkit-scrollbar-corner {
  background-color: #000;
}

.terminal-log::-webkit-scrollbar-thumb {
  background-color: #555;
}

.ant-transfer-list {
  flex: 1;
  height: auto !important;
}
